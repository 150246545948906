<template>
  <div class="csn-dap">
    <div class="modal-dialog csn-modal-dialog">
      <div class="modal-content">
        <button
          class="btn csn-modal-close-button"
          @click="handleCloseButtonClick"
        >
          <CloseIcon />
        </button>
        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('pay_and_play_session_details') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :type="messageTypeEnum.FAILURE" :message="message" />
          <Form
            class="mx-auto csn-modal-content-form"
            :ref="formRef"
            :id="formId"
          >
            <p>{{ t('due_to_regulations') }}</p>
            <Dropdown
              :name="name.GENDER"
              :label="t('salutation_label')"
              :dataSource="genderList"
              v-model="formData[name.GENDER]"
              :placeholder="t('salutation_placeholder')"
              :serverError="serverError[name.GENDER]"
              :validators="[validator.required]"
            />
            <p>{{ t('to_stay_up_to_date') }}</p>
            <TextField
              :type="inputType.TEXT"
              :label="t('email')"
              :name="name.EMAIL"
              v-model="formData[name.EMAIL]"
              :serverError="serverError[name.EMAIL]"
              :validators="[validator.required, validator.email]"
            />
            <div class="form-group">
              <CheckboxField
                :label="t('i_agree')"
                :name="name.GETS_UPDATES"
                v-model="formData[name.GETS_UPDATES]"
              />
            </div>
            <button
              class="btn casino-btn csn-dap-submit-btn"
              @click="submitForm"
              :disabled="isSubmitting"
            >
              <ButtonLoader v-if="isSubmitting" />
              <span v-else>{{ t('save_information') }}</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PAY_AND_PLAY_SESSION_DETAILS } from '@/constants'
import { generateUUID } from '@/helpers'
import { formMixin, modalMixin } from '@/mixins'
import { PayAndPlayApi } from '@/api'

const name = {
  GENDER: 'GENDER',
  EMAIL: 'EMAIL',
  GETS_UPDATES: 'GETS_UPDATES',
}
const formData = {
  [name.GENDER]: '',
  [name.EMAIL]: '',
  [name.GETS_UPDATES]: false,
}

export default {
  name: PAY_AND_PLAY_SESSION_DETAILS,
  mixins: [formMixin, modalMixin],
  components: {
    Form: () => import('@/components/FormData'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    TextField: () => import('@/components/TextField'),
    Dropdown: () => import('@/components/Dropdown'),
    CheckboxField: () => import('@/components/CheckboxField'),
  },
  data: () => ({
    formData: { ...formData },
  }),
  computed: {
    formId: () => `formID${generateUUID()}`,
    name: () => name,
    t() {
      return this.$createComponentTranslator(PAY_AND_PLAY_SESSION_DETAILS)
    },
    genderList() {
      return [
        { name: this.t('mr'), value: '0' },
        { name: this.t('mrs'), value: '1' },
      ]
    },
  },
  methods: {
    closeModal(fn) {
      return this.$emit('close', fn)
    },
    handleCloseButtonClick() {
      this.closeModal(this.goToHomepage)
    },
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true

      try {
        const request = {
          gender: this.formData[name.GENDER],
          email: this.formData[name.EMAIL],
          receivesNotifications: this.formData[name.GETS_UPDATES],
        }

        const { state, msg } = await PayAndPlayApi.patchSessionDetails(request)

        if (state === this.responseState.ERROR) {
          this.messageType = this.messageTypeEnum.FAILURE
          this.message = msg
          return
        }
      } catch (e) {
        console.dir(e)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>
